<template>
    <el-dialog
        :before-close="onClose"
        :close-on-click-modal="false"
        :model-value="show"
        custom-class="common-edit"
        title="绑定摄像头"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="200" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="设备序列号：" prop="deviceNumber">
                <el-input v-model="dataForm.deviceNumber" placeholder="请输入设备序列号"></el-input>
            </el-form-item>
            <el-form-item label="通道号：" prop="channelNumber">
                <el-input-number v-model="dataForm.channelNumber" :min="0" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { BIND_ASSETS_POLE_MONITOR } from "@/api/assets";

let props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    id: {
        type: [String, Number],
    },
});

let emit = defineEmits(["update:show", "update"]);

// 表单数据
let dataForm = ref({
    deviceNumber: "",
    channelNumber: 0,
});

let dataFormRef = ref();

let dataRules = {
    deviceNumber: [{ required: true, message: "请输入设备序列号！", trigger: "blur" }],
    channelNumber: [{ required: true, message: "请输入通道号！", trigger: "blur" }],
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            BIND_ASSETS_POLE_MONITOR({
                ...dataForm.value,
                assetId: props.id,
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("绑定成功");
                }
            });
        }
    });
};
</script>
