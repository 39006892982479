import { httpGet, httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取电杆资产列表
export function GET_ASSETS_POLE_LIST(params: object) {
    return httpPost("/pole/asset/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 获取电杆资产详情
export function GET_ASSETS_POLE_DETAIL(params: object) {
    return httpPost("/pole/asset/detail", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 新增电杆资产
export function ADD_ASSETS_POLE(params: object) {
    return httpPost("/pole/asset/add", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 编辑电杆资产
export function EDIT_ASSETS_POLE(params: object) {
    return httpPost("/sequence/asset/edit", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 删除电杆资产
export function DELETE_ASSETS_POLE(params: object) {
    return httpPost("/pole/asset/delete", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 批量绑定电杆资产
export function BIND_ASSETS_POLE(params: object) {
    return httpPost("/pole/asset/bind", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 电杆资产绑定摄像头
export function BIND_ASSETS_POLE_MONITOR(params: object) {
    return httpPost("/pole/asset/setMonitor", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 电杆派工获取部门列表
export function GET_DEPT_TREE_SELECT(params: object) {
    return httpGet("/system/dept/treeselect", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 电杆派工获取用户列表
export function GET_USER_LIST_BY_DEPT(params: object) {
    return httpPost("/system/user/listByDept", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}

// 电杆派工
export function ASSETS_POLE_DISPATCH(params: object) {
    return httpPost("/pole/asset/distribute", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
